@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.aliasTitle,
.geneNameType {
  margin-bottom: 20px;
}

.geneNameType {
  color: $ocean;
}

.tabInfoWrapper {
  margin-top: 50px;
}

.aliasWrapper::after,
.tabInfoWrapper::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 50px 0;
}

.assayNotes {
  margin-top: 20px;
  font-size: 13px;
  font-weight: $font-weight-book;
  color: #76716b;
  white-space: inherit;
  margin-bottom: 0;
}
