@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  margin-block: 20px;
  background-color: $white;
  border: 1px solid $ocean;
  border-radius: 6px;
}

.rootSingleItemPadding {
  padding-block-end: 22px;
  overflow: hidden;
}

.topWrapper {
  display: flex;
  flex-direction: column;
}

.topWrapperHeader {
  position: sticky;
  top: 0;
  background: white;
  z-index: $layerPriority;
  border-radius: 6px;
}

.panelTitleWrapper {
  display: flex;
  align-items: center;
  margin-inline-start: 22px;
  margin-block-start: 22px;
}

.panelTitle {
  margin-left: 12.66px;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 24px;
}

.requiredTextWrapper {
  display: flex;
  align-items: center;
  margin-inline-end: 20px;
  margin-left: auto;
}

.requiredText {
  color: $gray2;
  font-size: 14px;
  line-height: 18px;
}

.divider {
  background: $gray4;
  width: auto;
  height: 1px;
}

.iconLock {
  color: $gray2;
  height: 23;
  width: 23;
}

.iconCheck {
  min-width: 20px;
}
