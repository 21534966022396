@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.panelInfoWrapper {
  display: flex;
}

.panelImageWrapper {
  min-width: 80px;
  min-height: 80px;
  max-height: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  // default color for image of kit
  background: #99ba9d;

  @include screen-medium {
    min-width: 180px;
    min-height: 180px;
    max-height: 180px;
    max-width: 180px;
  }
}

.cardImage {
  width: 100%;
  height: auto;
}

.panelTextContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  @include screen-large {
    padding-left: 30px;
  }
}

.panelAdditionalData {
  margin: 10px 0;
  color: $gray1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include screen-medium {
    // to align modal on tablets according to text info position
    position: relative;
  }

  button {
    margin-left: 5px;
  }
}

.tooltipModalWrapper:not(:empty) {
  margin-top: 20px;
}

.cardGeneCountInnerContent {
  display: flex;
  text-align: left;
  white-space: nowrap;
}
