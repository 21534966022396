@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.titleRoot {
  text-decoration: none;
  cursor: pointer;
  text-align: left;

  h3 {
    color: black;
  }
}

.cardTitle {
  font-weight: $font-weight-medium;
  height: fit-content;
  max-width: 215px;
  margin-right: 10px;
  text-decoration: none;
  color: black;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  //disabled styleling check because stylelint errors that it cannot find a style with this name
  //this rule enables ellipsis at the end of 3 lines (works in Chrome/Safari)
  /* stylelint-disable */
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100px;

  &:hover {
    color: $deepTeal;
  }
}
