@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.assaysWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.assaysTitle {
  margin-bottom: 20px;
}

.assaysText {
  margin-bottom: 10px;

  /* styles for dynamic html */
  h1,
  h2,
  h3,
  h4 {
    display: none;
  }

  p:first-of-type {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    //disabled styleling check because stylelint errors that it cannot find a style with this name
    //this rule enables ellipsis at the end of 3 lines (works in Chrome/Safari)
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 100px;
  }

  p:not(:first-of-type) {
    overflow: hidden;
    opacity: 0;
    height: 0;
  }
  /* styles for dynamic html */
}

.tableCellIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamicAssayContent {
  p {
    margin-bottom: 20px;
  }

  h4 {
    margin: 20px 0;
    font-size: 18px;
    line-height: 22px;

    @include screen-medium {
      font-size: 19px;
    }

    @include screen-large {
      font-size: 21px;
      line-height: 24px;
    }
  }
}

.assayNotes {
  margin-top: 10px;
  font-size: 13px;
  font-weight: $font-weight-book;
  color: #76716b;
  white-space: inherit;
  margin-bottom: 0;
}

.assayCheckmark {
  height: 16px;
  width: 18px;

  path {
    fill: black;
  }
}
