@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.orderingTagsWrapper {
  display: flex;
  flex-direction: column;
}

.orderingTagWrapper {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  svg {
    margin-top: 5px;
    width: 20px;
    min-width: 19px;
  }
}

.orderingTagsText {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.tagTitle {
  font-weight: $font-weight-medium;
}

.tagDescription {
  color: $gray1;
  font-size: 14px;
}

.orderingTagsWrapper:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 20px 0 50px 0;
}

a.partnershipLink {
  font-size: 14px;
  margin-right: 20px;
}

.partnershipWrapper {
  margin-left: 10px;
}
