@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.addonDescription {
  color: $gray1;
  overflow: hidden;
  width: calc(100% - 80px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  //disabled styleling check because stylelint errors that it cannot find a style with this name
  //this rule enables ellipsis at the end of 3 lines (works in Chrome/Safari)
  /* stylelint-disable */
  -webkit-box-orient: vertical;
}

.addonTextWrapper {
  padding: 0 20px 10px 20px;
  display: flex;
  position: relative;

  &.paddingTop {
    padding-top: 10px;
  }

  p {
    color: $gray1;
    font-size: 14px;
  }

  ul {
    margin: 10px 0;

    li {
      font-size: 14px;
      color: $gray1;
      list-style-type: square;
      margin-left: 30px;

      &::marker {
        font-size: 12px;
      }
    }
  }
}

.addonLink {
  font-size: 14px;
}

.showLessBtn {
  position: absolute;
  bottom: 5px;
  right: 20px;
}
