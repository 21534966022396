@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.clinicalDescriptionTitle {
  margin-bottom: 20px;
}

.clinicalDescriptionWrapper:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 50px 0;
}

.clinicalDescriptionWrapper:last-child {
  margin-bottom: 50px;
}

.informationText {
  display: block;
  margin-bottom: 7px;

  ul {
    white-space: normal;

    li {
      list-style-type: square;
      margin-left: 30px;
      margin-bottom: 5px;

      &::marker {
        font-size: 10px;
      }
    }
  }
}

.informationNoWrap {
  // to make work three dots approach
  max-width: calc(100vw - 30px);
  display: inline-flex;

  :global(.gene-table-wrapper) {
    display: none;
  }

  p:not(.informationTitle) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 10px;
  }

  @include screen-large {
    max-width: 660px;
  }

  // hide on preview any additional content
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p:not(:first-of-type) {
    overflow: hidden;
    opacity: 0;
    height: 0;
    width: 0;
  }

  // show only text after title
  .informationTitle + p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    height: auto;
    width: auto;
  }
}

.informationTitle {
  font-weight: $font-weight-medium;
  margin-right: 5px;
  white-space: nowrap;
  display: inline;
  text-align: left;
}
