@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/layers.scss';

.root {
  width: 100%;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 80px;

  @include screen-large {
    width: 40%;
    position: relative;
    margin-top: 0;
  }
}

.geneTileWrapper {
  @include screen-large {
    position: sticky;
    top: 80px;
  }
}

.notScrollableWrapper {
  position: relative;
}

.geneCustomizationWrapper {
  background-color: $gray7;
  font-size: 16px;
  line-height: 24px;
  height: calc(100vh - 200px);
  overflow: scroll;

  border: 1px solid $gray4;
  padding-inline: 20px;
  padding-block-end: 30px;

  @include screen-large {
    box-shadow:
      inset 0 11px 5px -10px #cccccc,
      inset 0 -11px 5px -10px #cccccc;
    height: min-content;
    max-height: calc(100vh - (230px + 86px));
    padding-block-end: 0;
  }
}

.geneCustomizationWrapperClosed {
  display: none;

  @include screen-large {
    display: flex;
    border: 1px solid $gray4;
    overflow: scroll;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
}

.containerClosedOrder {
  display: none;

  @include screen-large {
    display: flex;
  }
}

.primaryPanelTitle {
  font-weight: $font-weight-medium;
  font-size: 22px;
  line-height: 30px;
}

.addToOrderButton {
  width: 100%;
  height: 46px;
}

.addToOrderButtonWrapper {
  padding: 20px 30px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $layerModal;

  @include screen-large {
    position: unset;
    left: unset;
    right: unset;
    width: unset;
    background-color: $gray7;

    border-bottom: 1px solid $gray4;
    border-inline: 1px solid $gray4;
  }
}

.questionsWrapper {
  display: none;

  @include screen-large {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-inline: auto;
    margin-block-start: 30px;
    padding-bottom: 30px;
  }
}

.backButton {
  svg {
    transform: rotate(90deg);
  }
  cursor: pointer;
  margin-right: 10px;

  @include screen-large {
    display: none;
  }
}

.titleWrapper {
  display: flex;
  padding-block-end: 20px;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $gray7;
  padding-block-start: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
  z-index: $layerModal;
  padding-inline: 20px;
  cursor: pointer;

  @include screen-large {
    box-shadow: none;
    cursor: default;
    border-top: 1px solid $gray4;
    border-inline: 1px solid $gray4;
  }
}

.titleWrapperClosed {
  display: none;

  @include screen-large {
    display: flex;
  }
}

.sectionGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  background-image: linear-gradient(rgba(255, 255, 255, 0), $gray5);
  width: calc(100% - 1px);
  z-index: $layerModal;
}
