@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  position: relative;
  overflow: unset;
  margin-top: 80px;

  @include screen-large {
    margin-top: 0;
  }
}

.rootHideForMobile {
  position: fixed;
  overflow: hidden;

  @include screen-large {
    position: relative;
    overflow: unset;
  }
}

.panelDataWrapper {
  display: flex;
  flex-direction: row;
}

.panelDetailWrapper {
  display: flex;
  flex-direction: column;
  margin-block-start: 28px;
  width: 100%;

  @include screen-large {
    width: calc(60% - 80px);
    margin-right: 80px;
  }
}

.border {
  height: 1px;
  width: 100%;
  background-color: $gray4;
  display: block;
  margin: 50px 0;
}

.smallerBorderBottom {
  margin-bottom: 20px;
}

.breadcrumbsContainer {
  margin-bottom: 20px;
  width: auto;
}
