@import '@invitae/ids-sass/sass/colors.scss';

.genesInfo {
  margin-inline-start: 58px;
  margin-block-end: 19px;
  font-size: 14px;
  line-height: 18px;
}

.primaryPanelGenesInfo {
  margin-inline-start: 54px;
}

.openedGeneTiles {
  color: $ocean;
}

.collapsedGeneTiles {
  color: $gray2;
}
