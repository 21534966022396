@import '@invitae/ids-sass/sass/colors.scss';

.associatedDisordersWrapper:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 50px 0;
}

.associatedDisordersTitle {
  margin-bottom: 20px;
}

.associatedDisordersContent {
  /* styles for dynamic html */
  h2:first-child,
  h1:first-child,
  h3:first-child,
  h4:first-child {
    display: none;
  }

  &.showThreeDots p:first-of-type {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    //disabled styleling check because stylelint errors that it cannot find a style with this name
    //this rule enables ellipsis at the end of 3 lines (works in Chrome/Safari)
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 100px;
  }

  p:not(:first-of-type) {
    overflow: hidden;
    opacity: 0;
    height: 0;
  }
  /* styles for dynamic html */
}

.disordersLink {
  margin-top: 10px;
}
