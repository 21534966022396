@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  position: relative;
  overflow: unset;
  margin-top: 80px;

  @include screen-large {
    margin-top: 0;
  }
}

.rootHideForMobile {
  position: fixed;
  overflow: hidden;

  @include screen-large {
    position: relative;
    overflow: unset;
  }
}

.breadcrumbsContainer {
  margin-bottom: 20px;
  width: auto;
}

.geneDataWrapper {
  display: flex;
  flex-direction: row;
}

.geneDetailWrapper {
  display: flex;
  flex-direction: column;
  margin-block-start: 28px;
  width: 100%;

  @include screen-large {
    width: calc(60% - 80px);
    margin-right: 80px;
  }
}

.productTypeText {
  white-space: pre-line;
  margin-bottom: 20px;
}
