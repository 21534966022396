@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.orderingInformationWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orderingInformationTitle {
  margin-bottom: 20px;
}

.informationText {
  display: inline;
  margin-bottom: 7px;

  ul {
    white-space: normal;

    li {
      list-style-type: square;
      margin-left: 30px;
      margin-bottom: 5px;

      &::marker {
        font-size: 10px;
      }
    }
  }
}

.informationTitle {
  font-weight: $font-weight-medium;
  margin-right: 5px;
  white-space: nowrap;
  display: inline;
  text-align: left;
}

.informationLink {
  margin-bottom: 10px;
}

.orderingInformationWrapper:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 50px 0;
}

.orderingInformationWrapper:last-child {
  margin-bottom: 50px;
}
