@import '@invitae/ids-sass/sass/colors.scss';

.panelDescriptionTitle,
.panelDescriptionText {
  margin-bottom: 20px;
}

.panelDescriptionText {
  white-space: pre-wrap;

  ul {
    white-space: normal;

    li {
      list-style-type: square;
      margin-left: 30px;
      margin-bottom: 5px;

      &::marker {
        font-size: 10px;
      }
    }
  }
}

.panelDescriptionWrapper:not(:last-child)::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: $gray4;
  margin: 50px 0;
}
