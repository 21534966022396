@import '@invitae/ids-sass/sass/font-weights.scss';

.modalComponentWrapper {
  block-size: revert;

  :first-child {
    max-block-size: unset;
  }
}

.panelModalContent {
  :global(.gene-table) {
    border-left: 1px solid rgb(232, 230, 226);
    border-right: 1px solid rgb(232, 230, 226);

    thead {
      background-color: rgb(58, 135, 173);

      tr td {
        border-top: 1px solid rgb(232, 230, 226);

        &:first-child {
          border-left: 1px solid rgb(232, 230, 226);
        }
      }

      tr th,
      td {
        padding: 10px;
        color: white;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-size: 14px;

        &:not(:first-child) {
          border-left: 1px solid rgb(232, 230, 226);
        }
      }
    }

    tbody {
      tr td {
        padding: 10px;
        border: 1px solid rgb(232, 230, 226);
        font-size: 14px;
      }
    }
  }

  :global(.gene-table-wrapper) {
    overflow: auto;
    max-width: calc(100vw - 50px);
    /* stylelint-disable selector-max-compound-selectors */
    :global(.table-alt-rows-color) tbody tr:nth-child(odd) td {
      background-color: #e7f5fb;
      width: 100%;
    }
    /* stylelint-enable selector-max-compound-selectors */
  }
}
