@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.geneTilesWrapper {
  margin-block: 20px;
  margin-inline-start: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: 0;
}

.geneTilesContainer {
  position: relative;
  padding-bottom: 25px;
}

.notClickable {
  pointer-events: none;
}

.showAllGenes {
  border: 1px solid $gray1;
  border-radius: 18px;
  color: $gray1;
  font-size: 12px;
  line-height: 18px;
  width: fit-content;
  padding-inline: 10px;
  margin-inline: auto;
  margin-block-end: 20px;
  cursor: pointer;
}

.tooltipButton {
  width: calc(33% - 12px);
  margin-inline-end: 10px;
  margin-block-end: 10px;
  cursor: pointer;
  height: 30px;
  min-width: 85px;

  @include screen-medium {
    width: calc(25% - 12px);
  }

  @include screen-large {
    height: 20px;
    width: calc(33% - 12px);
  }

  @include screen-extra-large {
    width: calc(25% - 12px);
  }
}

.wideTiles {
  min-width: 95px;

  @include screen-extra-large {
    width: calc(33% - 12px);
  }
}
