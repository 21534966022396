@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.geneName {
  margin-bottom: 20px;
}

.generalInfo {
  margin-bottom: 20px;
}

.noTabs {
  margin-bottom: 0;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $gray4;
    margin: 50px 0;
  }
}

.assayNotes {
  margin-top: 20px;
  font-size: 13px;
  font-weight: $font-weight-book;
  color: #76716b;
  white-space: inherit;
  margin-bottom: 0;
}
