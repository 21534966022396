@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  margin-top: 20px;
  background-color: $white;
  border: 1px solid $ocean;
  border-radius: 6px;
  padding-block-end: 20px;
  width: 100%;
}

.topWrapper {
  display: flex;
  flex-direction: column;
}

.geneTitleWrapper {
  display: flex;
  align-items: center;
  margin-inline-start: 20px;
  margin-block-start: 20px;
}

.geneTitle {
  margin-left: 10px;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 24px;
}

.radioTitle {
  label {
    margin-left: 3px;
    font-weight: $font-weight-medium;
    font-size: 16px;
    line-height: 24px;
  }
}
