@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.root {
  display: flex;
  align-items: center;
  height: inherit;
}

.checkIcon {
  margin-inline-start: 11.11px;
  margin-inline-end: 6.11px;
  margin-block-start: 7.22px;
  margin-block-end: 7.22px;
  height: auto;
}

.geneTitle {
  font-size: 11px;
  line-height: 18px;
  white-space: nowrap;
}

.unselectedGeneTitle {
  margin-inline-start: 25px;
}

.selected {
  background-color: $ocean;
  color: $white;
}

.unselected {
  background-color: $white;
  border: 1px solid $gray4;
  color: $ocean;
}
