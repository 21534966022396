@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.testsWrapper {
  background-color: $gray7;
  padding-bottom: 50px;
}

.testsTitle {
  margin-bottom: 20px;
}

.skeletonWrapper {
  grid-column: span 4;
}

.alternativeCategories {
  display: flex;
  flex-direction: column;

  a:not(:last-child) {
    margin-bottom: 15px;
  }
}
