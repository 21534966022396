@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.clinicalDescriptionTitle {
  margin-bottom: 20px;
}

.clinicalDescriptionText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // to make work three dots approach
  max-width: $large-min;
  margin-bottom: 10px;
}

.noWrapText {
  white-space: pre-wrap;
}

.clinicalDescriptionModalText {
  white-space: pre-wrap;
  height: 100%;
}
