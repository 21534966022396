@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights';

.addOnGenesTitleWrapper {
  margin-block-start: 40px;
  display: flex;
  align-items: center;
}

.addOnGenesTitle {
  font-weight: $font-weight-medium;
  font-size: 20px;
  line-height: 26px;
}
